<template>
  <div>
    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <Questionnaire
      v-else
      :id="id"
      :questions="questions"
      :data="data"
      :is-confirm-leave-page-ignored="isConfirmLeavePageIgnored"
    />
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useRequest from "@/composables/useRequest";
// Components
import Questionnaire from "./Questionnaire";

export default {
  components: {
    Questionnaire
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const { t } = useI18n();

    // Composables
    const { getText } = useTexts();
    const { request, isSuccess } = useRequest();

    // Data
    const data = ref({});
    const isLoading = ref(false);
    const isConfirmLeavePageIgnored = ref(false);

    // Computed
    const questions = computed(() => {
      const questions = data.value?.questionnaire ?? [];

      return questions.map(question => {
        return { question };
      });
    });

    // Methods
    const getData = async () => {
      const response = await request({
        endpoint: "learning.evaluations.begin",
        pathParams: {
          id: props.id
        }
      });

      if (!isSuccess(response)) {
        isConfirmLeavePageIgnored.value = true;
        router.go(-1);
      }

      data.value = response?.payload?.data ?? {};
    };

    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.preview")} - ${t("app.quizzes", 2)} - ${t("app.evaluations", 2)} - ${t("app.teaching")}`;

      const name = getText(data.value?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Lifecycle Hooks
    onMounted(async () => {
      isLoading.value = true;
      await getData();
      isLoading.value = false;
    });

    return {
      isConfirmLeavePageIgnored,
      questions,
      data,
      isLoading,
      documentTitle
    };
  }
};
</script>
